(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Language selector
     * -- Banner to change language
     *
     * Usages:
     *   - headerMenu.jsp
     *   - home.jsp
     *
     * Dependencies:
     *   - none
     */
    Vue.component("language-selector", {
        props: ["languages", "suggestedLanguage", "suggestedSubdomain", "i18n"],
        data: function () {
            return {
                selectedLanguage: this.suggestedLanguage,
                selectedSubdomain: this.suggestedSubdomain,
                displayLanguagePopover: "none",
                isActive: false,
                buttonContinue: "",
                changeLanguage: "",
            };
        },
        template: `
      <div class="language-selector" ref="languageSelector">
        <div class="language-selector__title">
          <div class="language-selector__label">{{changeLanguage}}&nbsp;</div>
          <button type="button" class="language-selector__close-mobile" v-bind:aria-label="i18n.txtClose" @click="close">
            <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/white-cross.svg" v-bind:alt="i18n.txtClose"/>
          </button>
        </div>
        
          <div class="language-selector__dropdown__wrapper">
            <div class="language-selector__dropdown" @click="openDropdown">
              <div class="language-selector__dropdown-toggle" v-bind:class="{'language-selector__dropdown-toggle-active': isActive}">
                {{selectedLanguage}}
                <div class="language-selector__dropdown--mask" v-bind:style="{display: displayLanguagePopover}"></div>
                <ul class="language-selector__dropdown__menu" v-bind:style="{display: displayLanguagePopover}" >
                  <li v-for="language in languages"
                    class="language-selector__dropdown__menu__item"
                    @click="setActiveLanguage(language)">
                    {{ language.text }}
                  </li>
                  <li class="language-selector__dropdown__menu__arrow"></li>
                </ul>
              </div>
              
            </div>
            <button class="language-selector__dropdown__continue" @click="goto">{{buttonContinue}}</button>
          <button type="button" class="language-selector__close-tablet" v-bind:aria-label="i18n.txtClose" @click="close">
            <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/white-cross.svg" v-bind:alt="i18n.txtClose"/>
          </button>
          </div>
        
        
      </div>
    `,
        mounted: function () {
            //set the text for buttonContinue in the suggested language
            for (let language of this.languages) {
                if (language.subdomain === this.selectedSubdomain) {
                    this.buttonContinue = language.continue;
                    this.changeLanguage = language.changeLanguage;
                    break;
                }
            }
            //sort
            this.languages.sort(function (a, b) {
                let languageNameA = a.text.toLowerCase();
                let languageNameB = b.text.toLowerCase();
                return languageNameA.localeCompare(languageNameB);
            });
        },
        methods: {
            close: close,
            openDropdown: openDropdown,
            setActiveLanguage: setActiveLanguage,
            goto: goto,
        },
    });

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */

    function close() {
        var actualSubdomain = _getSubdomain();
        if (actualSubdomain === "www") {
            actualSubdomain = "en";
        }
        if (_isAvailableLanguage(actualSubdomain, this.languages)) {
            setCookie("wikiloc_locale", actualSubdomain, 365);
        }
        gtagEvent("language_change_suggestion_dismiss", {
            lang: this.suggestedSubdomain,
        });
        this.$refs.languageSelector.remove();
        // Create the event
        var event = new CustomEvent("resize");

        // Dispatch/Trigger/Fire the event
        window.dispatchEvent(event);
    }

    function openDropdown() {
        this.isActive = !this.isActive;
        if (this.displayLanguagePopover === "none") {
            this.displayLanguagePopover = "block";
        } else {
            this.displayLanguagePopover = "none";
        }
    }

    function setActiveLanguage(languageSelected) {
        this.selectedLanguage = languageSelected.text;
        this.selectedSubdomain = languageSelected.subdomain;
        this.buttonContinue = languageSelected.continue;
        this.changeLanguage = languageSelected.changeLanguage;
    }

    function goto() {
        if (_isAvailableLanguage(this.selectedSubdomain, this.languages)) {
            setCookie("wikiloc_locale", this.selectedSubdomain, 365);
            gtagEvent("language_change_suggestion_accept", {
                lang: this.selectedSubdomain,
            });
            //go to the language selector to get the correct url
            if (document.getElementById("lang-selector")) {
                location.href = document.querySelector(
                    "#lang-selector li a#" + this.selectedSubdomain
                ).href;
            } else {
                //if the page doesn't have the language selector
                if (_isATrailPage()) {
                    location.href = _replaceSubdomain(
                        "www.wikiloc.com",
                        this.selectedSubdomain
                    );
                } else {
                    location.href = _replaceSubdomain(
                        location.href,
                        this.selectedSubdomain
                    );
                }
            }
        }
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    function _isATrailPage() {
        return location.href.match(/-\d+$/g);
    }

    function _getSubdomain() {
        var result = location.href.match(/:\/\/\w*\.\b/g);
        if (result) {
            var subdomain = result[0].substr(3);
            return subdomain.substr(0, subdomain.length - 1);
        }
        return "www";
    }

    function _replaceSubdomain(url, toSubdomain) {
        const replace = "://" + toSubdomain + ".";

        // Prepend http://
        if (!/^\w*:\/\//.test(url)) {
            url = "https://" + url;
        }

        // Check if we got a subdomain in url
        if (url.match(/\.\w*\b/g).length > 1) {
            return url.replace(/(:\/\/\w+\.)/, replace);
        }

        return url.replace(/:\/\/(\w*\.)/, `${replace}$1`);
    }

    function _getNavigatorLang() {
        return (
            navigator.languages
                ? navigator.languages[0]
                : navigator.language ||
                  navigator.userLanguage ||
                  navigator.browserLanguage ||
                  navigator.systemLanguage
        ).split("-")[0];
    }

    function _getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie =
            cname +
            "=" +
            cvalue +
            ";" +
            expires +
            ";path=/;domain=.wikiloc.com";
    }

    function _isAvailableLanguage(languageCode, languages) {
        for (var i = 0; i < languages.length; i++) {
            if (languages[i].subdomain === languageCode) {
                return true;
            }
        }
        return false;
    }

    function _hasToAppearLanguageSelector(languages) {
        var subdomainLang = _getSubdomain();
        if (subdomainLang === "www") {
            subdomainLang = "en";
        }
        var navigatorLang = _getNavigatorLang();
        var cookieLang = _getCookie("wikiloc_locale");
        if (
            typeof cookieLang != "undefined" &&
            cookieLang !== subdomainLang &&
            _isAvailableLanguage(cookieLang, languages)
        ) {
            return true;
        } else if (
            (typeof cookieLang == "undefined" || cookieLang === "") &&
            navigatorLang !== subdomainLang &&
            _isAvailableLanguage(navigatorLang, languages)
        ) {
            return true;
        }
        return false;
    }

    function _getSuggestedSubdomain(languages) {
        var subdomainLang = _getSubdomain();
        if (subdomainLang === "www") {
            subdomainLang = "en";
        }
        var navigatorLang = _getNavigatorLang();
        var cookieLang = _getCookie("wikiloc_locale");
        if (
            typeof cookieLang != "undefined" &&
            cookieLang !== subdomainLang &&
            _isAvailableLanguage(cookieLang, languages)
        ) {
            return cookieLang;
        } else if (
            (typeof cookieLang == "undefined" || cookieLang === "") &&
            navigatorLang !== subdomainLang &&
            _isAvailableLanguage(navigatorLang, languages)
        ) {
            return navigatorLang;
        }
        return "www";
    }

    function _getSuggestedLanguage(selectedSubdomain, languages) {
        for (var i = 0; i < languages.length; i++) {
            if (languages[i].subdomain === selectedSubdomain) {
                return languages[i].text;
            }
        }
        return "English";
    }

    ///////////////////////////////////////////////////////////////////////
    //A Vue Main (check for multiple instances)
    //if you change the value of ${elementId}, update banner-cta, as it depends on this id
    var elementId = "vue-language-selector";
    if (!document.getElementById(elementId)) {
        return null;
    }

    if (_hasToAppearLanguageSelector(INJECT_HEADER.languages)) {
        var suggestedSubdomain = _getSuggestedSubdomain(
            INJECT_HEADER.languages
        );
        var suggestedLanguage = _getSuggestedLanguage(
            suggestedSubdomain,
            INJECT_HEADER.languages
        );
        new Vue({
            el: "#" + elementId,
            data: {
                languages: INJECT_HEADER.languages,
                i18n: INJECT_HEADER.i18n,
                suggestedLanguage: suggestedLanguage,
                suggestedSubdomain: suggestedSubdomain,
            },
            template: `
			<language-selector
			  :i18n="i18n"
			  :languages="languages"
			  :suggestedLanguage="suggestedLanguage"
			  :suggestedSubdomain="suggestedSubdomain"
			>
			</language-selector>
		`,
        });
    }
})();
